import { Button } from "@mui/material";
import theme from "../../utils/theme";
import { ClickableComponentProps } from "../../types/types";

export const ContactUsButton: React.FC<ClickableComponentProps> = ({
  onClick,
}) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        color: "white",
        borderColor: "knockedWhite",
        fontWeight: "light",
        backgroundColor: theme.palette.primary.main,
        "&:hover": { borderColor: "white", backgroundColor: "primary.dark" },
      }}
    >
      Contact us
    </Button>
  );
};
