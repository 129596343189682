import React from "react";
import { Card, CardContent, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const CreateCcidCard: React.FC<{ openCcidModal: (bool: boolean) => void }> = ({
  openCcidModal,
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: 3,
        borderRadius: 2,
        height: "100%",
      }}
    >
      <Button
        aria-label="create new production"
        onClick={() => openCcidModal(true)}
        sx={{
          borderRadius: "50%",
          width: 64,
          height: 64,
          marginBottom: "1rem",
          backgroundColor: "#555",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#333",
          },
        }}
      >
        <AddIcon fontSize="large" />
      </Button>
      <CardContent sx={{ textAlign: "center" }}>
        <Typography variant="body2" color="textSecondary" letterSpacing="0.4px">
          If you don’t see the production you are looking for, create a new
          entry here.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CreateCcidCard;
