/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getRiskFormData } from "../../api/spotlight";

export const useRisk = (
  seriesId: string | undefined,
  type = "series",
): UseQueryResult<Record<string, any>, AxiosError> =>
  useQuery(
    ["risk", seriesId],
    () => getRiskFormData(seriesId as string, type),
    { enabled: Boolean(seriesId) },
  );
