/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { submitRiskFormData } from "../../api/spotlight";
import { noop } from "../../utils/appHelper";

export const useSubmitRisk = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(
    (payload: any) =>
      submitRiskFormData(payload.riskId, payload.formData, payload.type),
    { onSuccess, onError },
  );
