import { FC, useMemo } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Divider,
  Stack,
  Skeleton,
  Chip,
} from "@mui/material";
import { useMetadata, useProductionCardDetails } from "../../hooks";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProductionCardStatusSelect from "./ProductionCardStatusSelect";
import studioLogos from "../../logos/logos";
import theme from "../../utils/theme";

const ProductionCardRecent: FC<{ ccid: string }> = ({ ccid }) => {
  const { data: productionMetadata, isLoading: isLoadingMetaData } =
    useMetadata(ccid);
  const { data: productionCardData, isLoading: isLoadingProductionCardData } =
    useProductionCardDetails(ccid);

  const getProductionUrl = (): string => {
    switch (productionMetadata?.productionType) {
      case "SPECIAL":
        return `special/${ccid}`;
      case "FILM":
        return `special/${ccid}`;
      case "BRANDED_SPECIAL":
        return `programmes/${productionMetadata?.parentCcid}/series/0/titles/${ccid}`;
      default:
        return `programmes/${productionMetadata?.parentCcid}/series/${ccid}`;
    }
  };

  const getName = (): string | undefined => {
    switch (productionMetadata?.productionType) {
      case "EPISODE":
        return productionMetadata?.titleName || productionMetadata?.parentName;
      case "FILM" || "BRANDED_SPECIAL":
        return productionMetadata?.titleName || productionMetadata?.parentName;
      case "SPECIAL":
        return productionMetadata?.parentName
          ? `${productionMetadata?.parentName}: ${productionMetadata?.titleName}`
          : productionMetadata?.titleName;
      default:
        return (
          productionMetadata?.parentName ||
          productionMetadata?.seriesName ||
          productionMetadata?.titleName
        );
    }
  };

  const getProductionType = (): string => {
    switch (productionMetadata?.productionType) {
      case "EPISODE":
        return "Episode";
      case "FILM":
        return "Film";
      case "BRANDED_SPECIAL":
        return "Special";
      case "SPECIAL":
        return "One Off";
      case "SERIES":
        return "Series";
      default:
        return "";
    }
  };

  const productionYears = useMemo(() => {
    if (productionMetadata?.productionYear)
      return productionMetadata?.productionYear;

    if (productionMetadata) {
      if (
        productionMetadata.productionYearSpan?.earliest &&
        productionMetadata.productionYearSpan?.latest &&
        productionMetadata.productionYearSpan?.earliest !==
          productionMetadata.productionYearSpan?.latest
      ) {
        return `${productionMetadata.productionYearSpan?.earliest} - ${productionMetadata.productionYearSpan?.latest}`;
      }
      return (
        productionMetadata.productionYearSpan?.latest ||
        productionMetadata.productionYearSpan?.earliest ||
        null
      );
    }

    return "-";
  }, [productionMetadata]);

  const getProductionLabelImages = (): Array<{
    label: string;
    src: string;
  }> => {
    const result: Array<{ label: string; src: string }> = [];
    // it's possible to get the same label more than once, so remove duplicates
    const uniqueLabels = Array.from(
      new Set(productionMetadata?.productionLabels || []),
    );

    for (const label of uniqueLabels) {
      if (label in studioLogos) {
        result.push({ label, src: studioLogos[label] });
      }
    }

    return result;
  };

  return (
    <Card
      sx={{
        maxWidth: 350,
        borderRadius: 2,
        boxShadow: 3,
        position: "relative",
        borderTop: "4px solid #1976d2", // Blue top border
        height: "100%",
      }}
    >
      <CardContent>
        <Typography variant="overline" color="secondary" gutterBottom>
          {getProductionType()}
        </Typography>

        <Typography variant="h5" noWrap>
          {getName()}
        </Typography>

        <Stack direction="row" spacing={1}>
          {productionMetadata?.seriesNumber ? (
            <Chip
              sx={{
                background: "transparent",
                padding: "12px 4px",
              }}
              variant="outlined"
              color="primary"
              label={
                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                  letterSpacing="0.15px"
                >
                  Series {productionMetadata.seriesNumber}
                </Typography>
              }
              size="small"
            />
          ) : null}

          <Stack
            direction="row"
            spacing={1}
            divider={
              <Typography variant="body1" color={theme.palette.text.secondary}>
                |
              </Typography>
            }
          >
            {productionMetadata?.titleId &&
              (productionMetadata?.productionType === "FILM" ||
                productionMetadata?.productionType === "BRANDED_SPECIAL" ||
                productionMetadata?.productionType === "SPECIAL") && (
                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                >
                  {productionMetadata?.titleId}
                </Typography>
              )}

            {productionMetadata?.seriesId && (
              <Typography variant="body1" color={theme.palette.text.secondary}>
                {productionMetadata.seriesId}
              </Typography>
            )}

            {productionYears ? (
              <Typography variant="body1" color={theme.palette.text.secondary}>
                {productionYears}
              </Typography>
            ) : null}
          </Stack>
        </Stack>

        <Box>
          {isLoadingProductionCardData || isLoadingMetaData ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <ProductionCardStatusSelect
              productionStatus={productionCardData?.productionStatus}
              canEdit={!!productionCardData?.productionRiskRating}
              ccid={ccid || ""}
              recentProductionCard
              type={
                productionMetadata?.productionType === "SERIES"
                  ? "series"
                  : "titles"
              }
            />
          )}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {getProductionLabelImages().map(({ label, src }) => {
            return (
              <Box
                key={label}
                component="img"
                maxWidth="50px"
                maxHeight="64px"
                sx={{ objectFit: "contain" }}
                src={src}
                alt={`${label} logo`}
              />
            );
          })}

          <Button
            component={RouterLink}
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
            sx={{
              borderRadius: 20,
              textTransform: "none",
              marginLeft: "auto",
            }}
            to={getProductionUrl()}
          >
            View
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductionCardRecent;
