import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import { SynopsisResponsePayload } from "../types/types";

const API_BASE = getAPI(SERVICES.synopsis);

export const getSynopsis = (
  key: string,
  ccid: string,
): Promise<SynopsisResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/${key}/${ccid}/synopsis`)
    .then(({ data }: AxiosResponse) => data);

export const patchSynopsis = (
  key: string,
  ccid: string,
  payload: Record<string, string | undefined>,
): Promise<void> =>
  handleRequest
    .patch(`${API_BASE}/${key}/${ccid}`, payload)
    .then(() => undefined);
