/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { updateRiskFormData } from "../../api/spotlight";
import { noop } from "../../utils/appHelper";

export const useUpdateRisk = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(
    ({ riskId, formData, type }: any) =>
      updateRiskFormData(riskId, formData, type),
    { onSuccess, onError },
  );
