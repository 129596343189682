import React, { useState } from "react";
import { SearchUser } from "./SearchUser";
import { useAddUserOverride } from "../../hooks/mutations/useAddUserOverride";
import { useRemoveUserOverride } from "../../hooks/mutations/useRemoveUserOverride";
import { User, UserProfile } from "../../types/types";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { AppBar, Button, IconButton, Popover, Toolbar, Tooltip, Typography, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useQueryClient } from "react-query";

interface SwitchUserContainerProps {
  profile: UserProfile | undefined;
}

const SwitchUserContainer: React.FC<SwitchUserContainerProps> = ({ profile }) => {
  const theme = useTheme();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const queryClient = useQueryClient();
  const { mutate: switchUser } = useAddUserOverride();
  const { mutate: removeOverride } = useRemoveUserOverride();

  const toggleSearchBox = () => {
    setShowSearchBox(!showSearchBox);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    toggleSearchBox();
  };

  const handleSwitchUser = (selection: User | undefined) => {
    console.log("Switching user...");
    if (selection) {
      switchUser({ userEmail: selection.email }, {
        onSuccess: () => {
          queryClient.invalidateQueries("UserProfile");
          window.location.reload();
        }
      })
    } else {
      removeOverride("remove Me", {
        onSuccess: () => {
          queryClient.invalidateQueries("UserProfile");
          window.location.reload();
        }
      });
    }

    setShowSearchBox(false); // Optionally hide the search box after switching
  };

  return (
    <>
      {profile?.userType === "ADMIN" && (
        <Tooltip title="Switch User">
          <IconButton
            onClick={(e) => handleClick(e)}
            color="primary"
            aria-label="switch user"
            sx={{ "&:hover": { backgroundColor: `${theme.palette.primary.dark}` } }}
          >
            <TransferWithinAStationIcon sx={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      )}
      {profile?.viewingAs && (
        <AppBar position="fixed" sx={{ zIndex: 1000, top: "60px", width: "15%", left: "-1%", pl: "0px", backgroundColor: `${theme.palette.secondary.main}` }}>
          <Toolbar>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2">
                You are viewing the website as {profile?.viewingAs}.
              </Typography>
              <Button size="small" variant="outlined" color="inherit" onClick={() => handleSwitchUser(undefined)}>
                Exit View
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
      )}
      <Popover
        open={showSearchBox && profile?.viewingAs == null}
        anchorEl={anchorEl}
        onClose={() => setShowSearchBox(false)}
        sx={{ zIndex: 10004 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
      >
        <Box sx={{ padding: 2 }}>
          <SearchUser setUserOverride={handleSwitchUser} isOverridden={profile?.viewingAs != null} />
        </Box>
      </Popover>
    </>
  );
};

export default SwitchUserContainer;
