import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Lock as LockIcon } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { ItvLogo } from "../ItvLogo";
import NotificationsContainer from "../../containers/Notifications/NotificationsContainer";
import { ContactUs } from "../../components/ContactUs";
import { useLabelUserEntitlements } from "../../hooks/queries";
import TutorialVideos from "../TutorialVideos/TutorialVideos";
import { NavBarSearch } from "./NavBarSearch";
import { CompassGPT } from "../../compass-gpt/CompassGPT";
import { ContactUsButton } from "../../components/ContactUs/ContactUsButton";
import SwitchUserContainer from "../../containers/OverrideUser/SwitchUserContainer";
import { useGetUserProfile } from "../../hooks/queries/useGetUserProfile";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const { data: entitlement } = useLabelUserEntitlements();
  const { data: profile } = useGetUserProfile();

  const theme = useTheme();
  return (
    <Box
      sx={{
        bgcolor: "#22252F",
        height: 60,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px={4}
      >
        {/* Left hand side */}
        <Link to="/" style={{ textDecoration: "none" }}>
          <Stack direction="row" spacing={2}>
            <ItvLogo width={64} />
            <Typography
              variant="h6"
              color="white"
              fontWeight="regular"
              onClick={() => null}
            >
              Studios Portal
            </Typography>
          </Stack>
        </Link>

        <NavBarSearch />

        {/* Right hand side */}
        <Stack direction="row" spacing={2}>
          {entitlement?.canManageAccess ? (
            <>
              <Tooltip title="Access Management">
                <IconButton
                  color="primary"
                  aria-label="access management"
                  onClick={() => navigate(`/access-directory`)}
                  sx={{
                    "&:hover": {
                      backgroundColor: `${theme.palette.primary.dark}`,
                    },
                  }}
                >
                  <LockIcon sx={{ color: "white" }} />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" flexItem color="white" />
            </>
          ) : null}
          <CompassGPT />
          <TutorialVideos />
          <NotificationsContainer />
          {(profile?.userType === "ADMIN" || profile?.viewingAs) && <SwitchUserContainer profile={profile} />}
          <ContactUs ClickableComponent={ContactUsButton} />
          <Button
            onClick={() => oktaAuth.tokenManager.clear()}
            sx={{
              fontWeight: "light",
              color: "white",
              "&:hover": { backgroundColor: `${theme.palette.primary.dark}` },
            }}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default NavBar;
