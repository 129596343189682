/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getRiskScore } from "../../api/spotlight";
import { RiskScore } from "../../types/types";

export const useRiskScore = (
  riskId: string,
  type = "series",
): UseQueryResult<RiskScore, AxiosError> =>
  useQuery(["risk score", riskId], () => getRiskScore(riskId as string, type), {
    enabled: Boolean(riskId),
  });
