/* eslint-disable import/no-extraneous-dependencies */

import React, { useState, useEffect, useMemo, useCallback, FC } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Stack,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { NoAccess } from "../../../../components/NoAccess/index";

import { SynopsisResponsePayload } from "../../../../types/types";

import Synopsis, { SynopsisTypes } from "./Synopsis";
import { synopsisConfigs } from "./synopsisConfigs";
import { getNumberOfCharacters } from "../../../../utils/stringHelper";

import {
  NotificationService,
  SYNOPSIS_CHANGE,
} from "../../../../services/notificationService";
import { ActionBar } from "../../../../components";
import { useSynopsis, useUpdateSynopsis } from "../../../../hooks";
import { ErrorMessage } from "../../../../components/ErrorMessage";
import { SnackbarDismiss } from "../../../../componentsV2/SnackBarDismiss";

export const SYNOPSIS_SAVED = "SYNOPSIS_SAVED";
export interface SynopsisGroupProps {
  synopsisData?: SynopsisResponsePayload;
  synopsisUrlLevel: string;
  isLoadingSynopsisData: boolean | undefined;
  inModal?: boolean;
}

const SynopsisGroup: React.FC<SynopsisGroupProps> = ({
  synopsisData,
  synopsisUrlLevel,
  isLoadingSynopsisData,
  inModal = false,
}) => {
  const [saveBarIsVisible, setSaveBarIsVisible] = useState<boolean>(false);
  const [synopsisValues, setSynopsisValues] = useState<Record<string, string>>({
    synopsisShort: "",
    synopsisMedium: "",
    synopsisLong: "",
  });

  const { programmeCcid, seriesCcid, titleCcid } = useParams<string>();
  const activeCcid = titleCcid || seriesCcid || programmeCcid;

  const updatedSynopsis = useMemo(
    () => ({
      synopsisShort:
        synopsisValues.synopsisShort !== synopsisData?.synopsisShort
          ? synopsisValues.synopsisShort.trim()
          : synopsisData?.synopsisShort.trim(),
      synopsisMedium:
        synopsisValues.synopsisMedium !== synopsisData?.synopsisMedium
          ? synopsisValues.synopsisMedium.trim()
          : synopsisData?.synopsisMedium.trim(),
      synopsisLong:
        synopsisValues.synopsisLong !== synopsisData?.synopsisLong
          ? synopsisValues.synopsisLong.trim()
          : synopsisData?.synopsisLong.trim(),
    }),
    [
      synopsisData?.synopsisLong,
      synopsisData?.synopsisMedium,
      synopsisData?.synopsisShort,
      synopsisValues.synopsisLong,
      synopsisValues.synopsisMedium,
      synopsisValues.synopsisShort,
    ],
  );

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateSynopsis(
    synopsisUrlLevel,
    activeCcid as string,
    updatedSynopsis as Record<string, string>,
    () => {
      queryClient.invalidateQueries(synopsisUrlLevel);
      enqueueSnackbar("Synopsis has been updated", { variant: "success" });
      setSaveBarIsVisible(false);
    },
    () => {
      enqueueSnackbar("There is an error on updating synopsis", {
        variant: "error",
        action: SnackbarDismiss,
      });
    },
  );

  const handleSave = () => {
    mutate({});
  };

  const discardSynopsisChanges = useCallback(() => {
    const value = {
      synopsisShort: synopsisData?.synopsisShort || "",
      synopsisMedium: synopsisData?.synopsisMedium || "",
      synopsisLong: synopsisData?.synopsisLong || "",
    };

    setSynopsisValues(value);

    if (inModal) {
      NotificationService.pub(SYNOPSIS_CHANGE, {
        saveBarIsVisible,
        synopsisValues: value,
      });
    }
  }, [
    inModal,
    saveBarIsVisible,
    synopsisData?.synopsisLong,
    synopsisData?.synopsisMedium,
    synopsisData?.synopsisShort,
  ]);

  useEffect(() => {
    if (synopsisData) {
      const { synopsisShort, synopsisMedium, synopsisLong } = synopsisData;
      const newSynopsisData = {
        synopsisShort: synopsisShort || "",
        synopsisMedium: synopsisMedium || "",
        synopsisLong: synopsisLong || "",
      };
      setSynopsisValues(newSynopsisData);
    }
  }, [synopsisData, setSynopsisValues]);

  const warningMap = useMemo(
    () =>
      Object.entries(synopsisConfigs).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, [key, value]) => {
          acc[key] =
            getNumberOfCharacters(synopsisValues[key]) > value.lengthLimit;
          return acc;
        },
        {},
      ),
    [synopsisValues],
  );

  const hasSynopsisWarning = useMemo(
    () => Object.values(warningMap).filter(Boolean),
    [warningMap],
  );

  const handleTextChange = useCallback(
    (synopsisType: SynopsisTypes, value: string) => {
      setSynopsisValues((prevState) => {
        const newSynopsisData = {
          ...prevState,
          [synopsisType]: value,
        };

        if (inModal) {
          NotificationService.pub(SYNOPSIS_CHANGE, {
            saveBarIsVisible,
            synopsisValues: newSynopsisData,
          });
        }

        return newSynopsisData;
      });
    },
    [inModal, saveBarIsVisible],
  );

  if (isLoadingSynopsisData) {
    return (
      <Container>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15vh",
            }}
          >
            {" "}
            <CircularProgress data-testid="spinner" />
          </div>
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 2 }}>
      {synopsisData ? (
        <Stack spacing={2}>
          <Alert
            severity="info"
            action={
              <Button
                variant="outlined"
                size="small"
                endIcon={<LaunchIcon />}
                onClick={() =>
                  window.open(
                    "https://docs.google.com/document/d/1QI1QG0SlbzpYu5iJRfFvDSMHXGa8-rtK/edit",
                    "_blank",
                  )
                }
              >
                Guidance & Examples
              </Button>
            }
          >
            Find helpful guidance on how to fill out your synopsis here!
          </Alert>
          <Card>
            <CardContent>
              {["synopsisShort", "synopsisMedium"].map((synopsisItem) => (
                <Synopsis
                  key={synopsisItem}
                  synopsis={synopsisItem as SynopsisTypes}
                  setSaveBarIsVisible={setSaveBarIsVisible}
                  synopsisValue={
                    synopsisValues ? synopsisValues[synopsisItem] : ""
                  }
                  handleTextChange={handleTextChange}
                  userCanEditSynopsis={synopsisData.userCanEditSynopsis}
                  hasExceedLimit={warningMap[synopsisItem]}
                />
              ))}
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              {["synopsisLong"].map((synopsisItem) => (
                <Synopsis
                  key={synopsisItem}
                  synopsis={synopsisItem as SynopsisTypes}
                  setSaveBarIsVisible={setSaveBarIsVisible}
                  synopsisValue={
                    synopsisValues ? synopsisValues[synopsisItem] : ""
                  }
                  handleTextChange={handleTextChange}
                  userCanEditSynopsis={synopsisData.userCanEditSynopsis}
                  hasExceedLimit={warningMap[synopsisItem]}
                />
              ))}
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <NoAccess />
      )}
      {saveBarIsVisible && !inModal && (
        <ActionBar
          cancelLabel="Discard changes"
          confirmLabel="Save changes"
          confirmCallback={() => {
            if (hasSynopsisWarning.length <= 0) {
              handleSave();
            }
          }}
          cancelCallback={() => {
            discardSynopsisChanges();
            setSaveBarIsVisible(false);
          }}
          confirmLoading={isLoading}
          spaceBetween
          width="100%"
        />
      )}
    </Container>
  );
};

export const SynopsisGroupWithData: FC = () => {
  const { programmeCcid, seriesCcid, titleCcid } = useParams<string>();
  const activeCcid = titleCcid || seriesCcid || programmeCcid;
  const brandsOrSeriesUrl = programmeCcid === activeCcid ? "brands" : "series";
  const synopsisUrlLevel =
    titleCcid === activeCcid ? "titles" : brandsOrSeriesUrl;

  const {
    data: synopsisData,
    isLoading: isLoadingSynopsisData,
    error: synopsisError,
  } = useSynopsis(activeCcid, synopsisUrlLevel);

  if (synopsisError) return <ErrorMessage message={synopsisError.message} />;

  return (
    <SynopsisGroup
      synopsisData={synopsisData}
      synopsisUrlLevel={synopsisUrlLevel}
      isLoadingSynopsisData={isLoadingSynopsisData}
      inModal={false}
    />
  );
};

export default SynopsisGroup;
