/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { PersonOutline, MailOutline } from "@mui/icons-material";
import { Field, FormikValues } from "formik";
import { FC } from "react";
import { StyledSectionTitle } from "../../../../components";
import { StyledSectionDescription } from "../../../../components/DynamicForm/DynamicForm.styles";

import { CustomAutocompleteField } from "../../../../componentsV2/FormFields/CustomAutocompleteField";
import theme from "../../../../utils/theme";
import { StartupTextField } from "./StartupTextField";

export interface BasicInfoProps {
  values: FormikValues;
  blurHandler: (id: string, newValue: any) => void;
  metaDataDropdown: Record<string, string[]> | undefined;
}

function convertToBoolean(value: string): boolean {
  return value === "true";
}

export const BasicInfoForm: FC<BasicInfoProps> = ({
  values,
  blurHandler,
  metaDataDropdown,
}) => (
  <>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <StyledSectionTitle>Basic Information</StyledSectionTitle>
        <StyledSectionDescription>
          This information includes rudimentary and technical information
          regarding your production
        </StyledSectionDescription>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={6}>
            {/* Production Status */}
            <FormLabel
              required
              sx={{ display: "block", marginBottom: "0.5rem" }}
            >
              Production status
            </FormLabel>
            <Field
              component={CustomAutocompleteField}
              options={metaDataDropdown?.productionStatus || []}
              name="basicInfo.productionStatus"
              handleAutoSaveBlur={blurHandler}
            />

            {/* Production Type */}
            <FormControl>
              <Box display="flex" alignItems="center">
                <FormLabel
                  id="basicInfo.productionType"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <>
                    <Typography display="inline">
                      Production Type
                      <Typography
                        color={theme.palette.sunsetOrange}
                        display="inline"
                      >
                        {" "}
                        *
                      </Typography>
                    </Typography>
                  </>
                </FormLabel>
              </Box>
              <RadioGroup
                aria-labelledby="radio-productionType"
                name="basicInfo.productionType"
                row={false}
                onBlur={() =>
                  blurHandler(
                    "basicInfo.productionType",
                    values.basicInfo.productionType,
                  )
                }
              >
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.productionType"
                      as={Radio}
                    />
                  }
                  value="Broadcast"
                  label="Show for broadcast"
                />
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.productionType"
                      as={Radio}
                    />
                  }
                  value="Sizzle"
                  label="Development/pilot/taster show/sizzle"
                />
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.productionType"
                      as={Radio}
                    />
                  }
                  value="Other"
                  label="Other"
                />
              </RadioGroup>
            </FormControl>

            {/* Other Production Type */}
            {/* TODO: this is not saving correctly in the backend, needs to be fixed */}
            {values.basicInfo.productionType === "Other" && (
              <Field
                component={StartupTextField}
                label="Other Production Type"
                fieldId="basicInfo.otherProductionType"
                required
                handleAutoSaveBlur={blurHandler}
              />
            )}

            {/* Broadcaster / Network Name */}
            <FormLabel
              required
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Broadcaster / Network Name
            </FormLabel>

            <Field
              component={CustomAutocompleteField}
              options={metaDataDropdown?.broadcaster || []}
              name="basicInfo.broadcaster"
              handleAutoSaveBlur={blurHandler}
            />

            {/* Other Broadcaster */}
            {values.basicInfo.broadcaster === "Other" && (
              <>
                <Box height={2} />
                <Field
                  component={StartupTextField}
                  label="Other Broadcaster"
                  fieldId="basicInfo.otherBroadcaster"
                  required
                  handleAutoSaveBlur={blurHandler}
                />
              </>
            )}

            {/* is this show scripted or unscripted? */}
            <FormControl>
              <Box display="flex" alignItems="center">
                <FormLabel
                  id="basicInfo.contentType"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <>
                    <Typography display="inline">
                      Is this show scripted or unscripted?
                      <Typography
                        color={theme.palette.sunsetOrange}
                        display="inline"
                      >
                        {" "}
                        *
                      </Typography>
                    </Typography>
                  </>
                </FormLabel>
              </Box>
              <RadioGroup
                aria-labelledby="radio-contentType"
                name="basicInfo.contentType"
                row={false}
                onBlur={() =>
                  blurHandler(
                    "basicInfo.contentType",
                    values.basicInfo.contentType,
                  )
                }
              >
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.contentType"
                      as={Radio}
                    />
                  }
                  value="Scripted"
                  label="Scripted"
                />
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.contentType"
                      as={Radio}
                    />
                  }
                  value="Non-Scripted"
                  label="Non-scripted"
                />
              </RadioGroup>
            </FormControl>

            {/* Is this show a special? */}
            <FormControl>
              <Box display="flex" alignItems="center">
                <FormLabel
                  id="basicInfo.recordingType"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <>
                    <Typography display="inline">
                      Is this show prerecorded or live?
                    </Typography>
                  </>
                </FormLabel>
              </Box>
              <RadioGroup
                aria-labelledby="radio-recordingType"
                name="basicInfo.recordingType"
                row={false}
                onBlur={() =>
                  blurHandler(
                    "basicInfo.recordingType",
                    values.basicInfo.recordingType,
                  )
                }
              >
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.recordingType"
                      as={Radio}
                    />
                  }
                  value="PreRecorded"
                  label="Pre-recorded"
                />
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.recordingType"
                      as={Radio}
                    />
                  }
                  value="Live"
                  label="Live"
                />
              </RadioGroup>
            </FormControl>

            {/* outsideLocation */}
            <FormControl>
              <Box display="flex" alignItems="center">
                <FormLabel
                  id="basicInfo.outsideLocation"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <>
                    <Typography display="inline">
                      Will any production be taking place outside of your base
                      location?
                      <Typography
                        color={theme.palette.sunsetOrange}
                        display="inline"
                      >
                        {" "}
                        *
                      </Typography>
                    </Typography>
                  </>
                </FormLabel>
              </Box>
              <RadioGroup
                aria-labelledby="radio-outsideLocation"
                name="basicInfo.outsideLocation"
                row={false}
                value={String(values.basicInfo.outsideLocation)}
                onBlur={() =>
                  blurHandler(
                    "basicInfo.outsideLocation",
                    convertToBoolean(values.basicInfo.outsideLocation),
                  )
                }
              >
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.outsideLocation"
                      as={Radio}
                    />
                  }
                  value="true"
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Field
                      type="radio"
                      name="basicInfo.outsideLocation"
                      as={Radio}
                    />
                  }
                  value="false"
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <StyledSectionTitle>Production Team Details</StyledSectionTitle>
        <StyledSectionDescription>
          Who is the core team and label working on this production?
        </StyledSectionDescription>

        {/* Production Label */}
        <Grid item xs={6} paddingBottom={2}>
          <FormLabel sx={{ display: "block", marginBottom: "0.5rem" }}>
            Production Label
          </FormLabel>
          <TextField
            value={values.basicInfo.productionLabel}
            size="small"
            disabled
            fullWidth
          />
        </Grid>

        <Grid container spacing={2}>
          {/* Production contact name */}
          <Grid item xs={6}>
            <Field
              component={StartupTextField}
              fullWidth
              size="small"
              placeholder="Forename Surname"
              label="Production contact name"
              fieldId="basicInfo.productionContactName"
              required
              startAdornment={<PersonOutline />}
              handleAutoSaveBlur={blurHandler}
            />
          </Grid>

          {/* Production contact email */}
          <Grid item xs={6}>
            <Field
              component={StartupTextField}
              fullWidth
              type="email"
              size="small"
              placeholder="example@itv.com"
              label="Production contact email"
              fieldId="basicInfo.productionContactEmail"
              required
              startAdornment={<MailOutline />}
              handleAutoSaveBlur={blurHandler}
            />
          </Grid>

          {/* Production creative lead name */}
          <Grid item xs={6}>
            <Field
              component={StartupTextField}
              fullWidth
              size="small"
              placeholder="Forename Surname"
              label="Production creative lead name"
              fieldId="basicInfo.creativeLeadName"
              required
              startAdornment={<PersonOutline />}
              handleAutoSaveBlur={blurHandler}
            />
          </Grid>

          {/* Production creative lead email */}
          <Grid item xs={6}>
            <Field
              component={StartupTextField}
              fullWidth
              type="email"
              size="small"
              placeholder="example@itv.com"
              label="Production creative lead email"
              fieldId="basicInfo.creativeLeadEmail"
              required
              startAdornment={<MailOutline />}
              handleAutoSaveBlur={blurHandler}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>
);
