/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getLegalData } from "../../api/spotlight";
// import { RiskScore } from '../../types/types';

export const useGetOfcomData = (
  ccid: string,
  provisional: boolean,
): UseQueryResult<any, AxiosError> => {
  const formType = provisional ? `OFCOM_PROVISIONAL` : `OFCOM_FINAL`;
  return useQuery(
    ["ofcomData", ccid],
    () => getLegalData(ccid as string, formType),
    {
      enabled: Boolean(ccid),
    },
  );
};
