/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Summary } from "../containers/NotificationForm/FormStatus";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import { RiskScore, LegalDeliverableData } from "../types/types";

const API_BASE = getAPI(SERVICES.spotlight);

export const getRiskFormData = (
  riskId: string,
  type = "series",
): Promise<Record<string, any>> => {
  const requestUrl = `${API_BASE}/v2/${type}/${riskId}/risk`;

  return (
    handleRequest
      // .get(`https://itv-notification.free.beeceptor.com/risk`)
      .get(requestUrl, { timeout: 60000 })
      .then(({ data }) => data)
  );
};

export const getSummary = (riskId: string, type = "series"): Promise<Summary> =>
  handleRequest
    .get(`${API_BASE}/v2/${type}/${riskId}/risk/summary`, { timeout: 60000 })
    .then(({ data }) => data);

export interface RiskFormProductionInfo {
  label: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
}

export const getRiskFormProductionInfo = (
  ccid: string,
  ccidLevel = "series",
): Promise<RiskFormProductionInfo> =>
  handleRequest
    .get(`${API_BASE}/v2/${ccidLevel}/${ccid}/risk/production-info`, {
      timeout: 60000,
    })
    .then(({ data }) => data);

export const updateRiskFormData = (
  riskId: string,
  formData: Record<string, any>,
  type = "series",
): Promise<Record<string, any>> => {
  const url = `${API_BASE}/v2/${type}/${riskId}/risk`;

  return handleRequest
    .put(url, formData, { timeout: 60000 })
    .then(({ data }) => data);
};

export const submitRiskFormData = (
  riskId: string,
  formData: Record<string, any>,
  type = "series",
): Promise<Record<string, any>> => {
  const url = `${API_BASE}/v2/${type}/${riskId}/risk/submit`;
  return new Promise((resolve, reject) => {
    handleRequest
      .put(url, formData, { timeout: 60000 })
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

export const getMetaDataDropdown = (): Promise<Record<string, string[]>> => {
  const url = `${API_BASE}/v2/metadata/dropdown`;
  return handleRequest.get(url, { timeout: 60000 }).then(({ data }) => data);
};

export const getRiskScore = (
  riskId: string,
  type = "series",
): Promise<RiskScore> =>
  handleRequest
    .get(`${API_BASE}/v2/${type}/${riskId}/score`, { timeout: 60000 })
    .then(({ data }) => data);

export const getLegalData = (
  ccid: string,
  formType: string,
): Promise<LegalDeliverableData> => {
  return handleRequest
    .get(
      `${API_BASE}/v2/ccids/${ccid}/legal-deliverables?formType=${formType}`,
      { timeout: 60000 },
    )
    .then(({ data }) => data);
};

export const getLegalDeliverableFormStatus = (
  ccid: string,
  formType: string,
): Promise<{ completed: boolean }> => {
  return handleRequest
    .get(
      `${API_BASE}/v2/ccids/${ccid}/tasks/legal-deliverables/status?formType=${formType}`,
      {
        timeout: 60000,
      },
    )
    .then(({ data }) => data);
};

export const legalDeliverablesFormSubmission = (
  ccid: string,
  formData: any,
  autosave: boolean,
  formType: string,
): Promise<Record<string, any>> => {
  const url = autosave
    ? `${API_BASE}/v2/ccids/${ccid}/legal-deliverables`
    : `${API_BASE}/v2/ccids/${ccid}/legal-deliverables/submit`;
  return new Promise((resolve, reject) => {
    handleRequest
      .put(
        url,
        {
          data: formData,
          formType: formType,
        },
        { timeout: 60000 },
      )
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

// ^ the above provisional field might need adding to the other endpoints
