import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { removeUserOverride } from "../../api/accessPermissions";

export const useRemoveUserOverride = (): UseMutationResult<
  unknown,
  unknown,
  unknown,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      removeUserOverride(),
    {
      onSettled: () => {
        queryClient.invalidateQueries("removeAddUserOverride");
      },
    },
  );
};
