import { useState } from "react";
import * as Yup from "yup";
import { Box, Grid, Stack, Typography } from "@mui/material";
import theme from "../utils/theme";
import { School, Search } from "@mui/icons-material";
import Explore from "@mui/icons-material/Explore";
import { VisuallyHidden } from "./VisuallyHidden";
import ProductionCardRecent from "./ProductionCard/ProductionCardRecent";

const recentProductionsSchema = Yup.array()
  .of(
    Yup.object({
      programmeCcid: Yup.string().optional(),
      seriesCcid: Yup.string().optional(),
      titleCcid: Yup.string().optional(),
      pathname: Yup.string().required(),
      dateActive: Yup.string().required(),
    }),
  )
  .required();

type RecentProductions = Yup.InferType<typeof recentProductionsSchema>;

export function RecentProductionsList() {
  const [recentlyActiveProductions] = useState<RecentProductions>(() => {
    const recentProductions = JSON.parse(
      localStorage.getItem("recentlyActiveProductions") || "[]",
    );

    if (recentProductionsSchema.isValidSync(recentProductions)) {
      return recentProductions.sort(function mostRecentFirst(a, b) {
        return Date.parse(b.dateActive) - Date.parse(a.dateActive);
      });
    } else {
      return [];
    }
  });

  return (
    <Grid container wrap="wrap" spacing="8px">
      {recentlyActiveProductions.length > 0 ? (
        recentlyActiveProductions.map((production) => {
          return (
            <Grid item xs={4} key={production.pathname}>
              <ProductionCardRecent
                ccid={production.pathname.substring(
                  production.pathname.lastIndexOf("/") + 1,
                )}
              />
            </Grid>
          );
        })
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          spacing={2}
        >
          <Box component="span" fontSize="80px">
            <Search
              fontSize="inherit"
              htmlColor={theme.palette.text.secondary}
            />
          </Box>

          <Stack spacing={1}>
            <Typography
              variant="h6"
              letterSpacing="0.15px"
              color={theme.palette.text.primary}
            >
              Looks like you haven't explored any productions in the portal yet!
            </Typography>

            <div>
              <Typography
                variant="body2"
                letterSpacing="0.17px"
                color={theme.palette.text.primary}
              >
                Get started using the portal by:
              </Typography>

              <ul>
                <li>
                  <Typography
                    variant="body2"
                    letterSpacing="0.17px"
                    color={theme.palette.text.primary}
                  >
                    searching for a production using the search bar located in
                    the header above
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body2"
                    letterSpacing="0.17px"
                    color={theme.palette.text.primary}
                  >
                    watching some tutorials by clicking the{" "}
                    <Box component="span" fontSize="20px">
                      <VisuallyHidden>tutorial videos</VisuallyHidden>
                      <School fontSize="inherit" />
                    </Box>{" "}
                    icon
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body2"
                    letterSpacing="0.17px"
                    color={theme.palette.text.primary}
                  >
                    chatting with the compass AI chatbot by clicking the{" "}
                    <Box component="span" fontSize="20px">
                      <VisuallyHidden>compass GPT</VisuallyHidden>
                      <Explore fontSize="inherit" />
                    </Box>{" "}
                    icon
                  </Typography>
                </li>
              </ul>
            </div>
          </Stack>
        </Stack>
      )}
    </Grid>
  );
}
