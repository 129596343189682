/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getSummary } from "../../api/spotlight";
import { Summary } from "../../containers/NotificationForm/FormStatus";

export const useRiskSummary = (
  riskId: string | undefined,
  type = "series",
): UseQueryResult<Summary, AxiosError> =>
  useQuery(["risk summary", riskId], () => getSummary(riskId as string, type), {
    enabled: Boolean(riskId),
  });
