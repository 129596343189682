/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  User,
  AccessPermissionsEntitlements,
  Group,
  UserProfile,
} from "../types/types";

const API_BASE = getAPI(SERVICES.access_permissions);

export const getCollaborators = (ccid: string): Promise<User[]> =>
  handleRequest.get(`${API_BASE}/${ccid}/users`).then(({ data }) => data);

export const addCollaborator = (
  ccid: string,
  userEmail: string,
): Promise<void> =>
  handleRequest
    .put(`${API_BASE}/${ccid}/users`, JSON.stringify(userEmail))
    .then(({ data }) => data);

export const removeCollaborator = async (
  ccid: string,
  userEmail: string,
): Promise<void> =>
  handleRequest
    .delete(`${API_BASE}/${ccid}/users`, JSON.stringify(userEmail))
    .then(({ data }) => data);

export const getGroupUsers = (groupId: string): Promise<User[]> =>
  handleRequest
    .get(`${API_BASE}/groups/${groupId}/users`)
    .then(({ data }) => data);

export const getGroupLabelUsers = (groupId: string): Promise<User[]> =>
  handleRequest
    .get(`${API_BASE}/groups/${groupId}/access-control`)
    .then(({ data }) => data);

export const getGroups = (): Promise<Group[]> =>
  handleRequest.get(`${API_BASE}/groups`).then(({ data }) => data);

export const getUserProfile = (): Promise<UserProfile> =>
  handleRequest.get(`${API_BASE}/users/profile`).then(({ data }) => data);

export const updateGroupAdminPermission = (
  userEmail: string,
  groupId: string,
  permissions: any,
  permissionToEdit: any,
): Promise<{ message: string }> => {
  const isDelete = permissions.includes(permissionToEdit);
  const permissionsPayload = {
    user: userEmail,
    accessList: permissionToEdit,
  };
  if (isDelete) {
    return handleRequest
      .delete(
        `${API_BASE}/groups/${groupId}/access-control?user=${userEmail}&accessList=${permissionToEdit}`,
      )
      .then(({ data }) => data);
  }
  return handleRequest
    .put(`${API_BASE}/groups/${groupId}/access-control`, permissionsPayload)
    .then(({ data }) => data);
};

export const searchUsers = (query: string, limit: number): Promise<User[]> =>
  handleRequest
    .get(`${API_BASE}/users?searchTerm=${query}&limit=${limit}`)
    .then(({ data }) => data);

export const addUser = (userId: string, groupId: string): Promise<any> =>
  handleRequest.put(`${API_BASE}/groups/${groupId}/users/${userId}`);

export const addUsers = (userIds: string[], groupId: string): Promise<any> => {
  const promises = userIds.map((userId) => addUser(userId, groupId));
  return Promise.all(promises);
};

export const removeUser = (userId: string, groupId: string): Promise<any> =>
  handleRequest.delete(`${API_BASE}/groups/${groupId}/users/${userId}`);

export const removeUsers = (
  userIds: string[],
  groupId: string,
): Promise<any> => {
  const promises = userIds.map((userId) => removeUser(userId, groupId));
  return Promise.all(promises);
};

export const getUserEntitlements = (): Promise<AccessPermissionsEntitlements> =>
  handleRequest.get(`${API_BASE}/users/entitlements`).then(({ data }) => data);

export const requestNpnfAccess = (ccid: string): Promise<void> =>
  handleRequest
    .post(`${API_BASE}/ccid/${ccid}/request-access/npnf`, {})
    .then(({ data }) => data);

export const addUserOverride = (userEmail: string): Promise<void> =>
  handleRequest
    .post(`${API_BASE}/override`, { newUser: userEmail })
    .then(({ data }) => data);

export const removeUserOverride = (): Promise<void> =>
      handleRequest
        .delete(`${API_BASE}/override`)
        .then(({ data }) => data);  
