/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { SERIES, TITLES } from "../../constants";
import { useGrantNotificationFormAccess } from "../../hooks";

type ErrorMapping = {
  [key: string]: string;
};

type NotificationFormErrorProps = {
  riskError: AxiosError | null;
};

const ErrorMappingResponse: ErrorMapping = {
  "Request failed with status code 400":
    "There was an error locating this form. Please ensure the URL is correct and try again, if this fix the issue please contact the Studios Portal team.",
  "Request failed with status code 401":
    "Authentication error. Please ensure you have the correct credentials to access this content and refresh the page. If the problem presists please request access to this content.",
  "Request failed with status code 403":
    "We don't recognise your account as having full access to this material. You can request access below or view the information we have here with limited access.",
  "Request failed with status code 404":
    "This form was not found, please inform the Studios Portal team and try again later.",
  "Network Error":
    "There was a 'Network Error', please check your VPN connection and refresh the page. If this doesn't fix the issue please contact the Studios Portal team.",
};

export const NotificationFormError: FC<NotificationFormErrorProps> = ({
  riskError,
}) => {
  const navigate = useNavigate();
  const { programmeCcid, seriesCcid, titleCcid } = useParams();
  const type = titleCcid ? TITLES : SERIES;
  const riskId = titleCcid || seriesCcid;
  const { mutate: requestAccess } = useGrantNotificationFormAccess();
  const [accessRequested, setAccessRequested] = useState<boolean>(false);

  const redirectUrl =
    type === TITLES
      ? `/special/${riskId}`
      : `/programmes/${programmeCcid}/series/${seriesCcid}`;

  const errorMessage =
    riskError && ErrorMappingResponse[riskError.message]
      ? ErrorMappingResponse[riskError.message]
      : "There was an error, please reload the page.";

  return (
    <Paper sx={{ p: 2, m: 2 }}>
      <Stack justifyContent="center" alignItems="center" m={2} spacing={2}>
        <Typography textAlign="center">{errorMessage}</Typography>
        {accessRequested && (
          <Typography>
            A request has been sent to the access manager. You will recieve a
            notification once access has been granted.
          </Typography>
        )}
        <Stack direction="row" spacing={2}>
          <Button
            disabled={accessRequested}
            onClick={() => {
              requestAccess(riskId || "");
              setAccessRequested(true);
            }}
            variant="contained"
          >
            Request access
          </Button>
          <Button variant="outlined" onClick={() => navigate(redirectUrl)}>
            Return to tasks
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
