/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";
import { DynamicForm, FormHeader, SideNav } from "../../components";
import {
  basicFormConfig,
  healthAndSafetyFormConfig,
  insuranceFormConfig,
  cyberSecurityFormConfig,
  sustainabilityFormConfig,
  complianceFormConfig,
  dutyOfCareFormConfig,
  securityFormConfig,
  dataPrivacyFormConfig,
} from "./formConfig";
import {
  useRisk,
  useSubmitRisk,
  useMetadataDropdown,
  useUpdateRisk,
} from "../../hooks";
import {
  validateFormData,
  sanitizeFormData,
  initSelectOptions,
  transFormDataBaseOnTypeAndValue,
  getLatestDate,
  convertToHtmlStr,
} from "./notificationForm.helper";
import { ModalWrapper } from "../../components/ModalWrapper";
import { ModalService } from "../../services/modalService";
import { TITLES, SERIES } from "../../constants";
import { RiskContact } from "./RiskContact";
import { useRiskScore } from "../../hooks/queries/useRiskScore";
import { RiskAssessor } from "../../types/types";
import { RiskScoreDialog } from "./RiskScoreDialog";
import { useRiskSummary } from "../../hooks/queries/useRiskSummary";
import { NotificationFormError } from "./NotificationFormError";

const basic: any = {
  label: "Production",
  id: "basicInfo",
  formConfig: basicFormConfig,
  description: (
    <div>
      This form brings all elements of risk together in one form, helping us
      review, manage, escalate and report on risk across key areas of risk.
      <p>
        You should get the form completed as soon as possible - ideally before
        the start-up meeting - to help us ensure you have the right support to
        manage potential risks. All the information you provide in the form will
        help us maintain a log of all current and ongoing production.
      </p>
      We are constantly updating and improving this form, so if you have any
      questions or feedback - please let us know by emailing risk@itv.com.
    </div>
  ),
};

const insurance = {
  label: "Insurance & Procurement",
  id: "insurance",
  formConfig: insuranceFormConfig,
};

const cyberSecurity = {
  label: "Cyber Security",
  id: "cyberSecurity",
  formConfig: cyberSecurityFormConfig,
};

const dataPrivacy = {
  label: "Data Privacy",
  id: "dataPrivacy",
  formConfig: dataPrivacyFormConfig,
};

const compliance = {
  label: "Compliance",
  id: "compliance",
  formConfig: complianceFormConfig,
};

const dutyOfCare = {
  label: "Duty Of Care",
  id: "dutyOfCare",
  formConfig: dutyOfCareFormConfig,
};

const healthAndSafety = {
  label: "Health & Safety",
  id: "healthAndSafety",
  formConfig: healthAndSafetyFormConfig,
};

const security = {
  label: "Security",
  id: "security",
  formConfig: securityFormConfig,
};

const sustainability = {
  label: "Sustainability",
  id: "sustainability",
  formConfig: sustainabilityFormConfig,
};

export const groupMenuSetting = [
  basic,
  compliance,
  dutyOfCare,
  insurance,
  healthAndSafety,
  dataPrivacy,
  cyberSecurity,
  sustainability,
  security,
];


export interface NotificationFormProps {
  formTitle?: string;
}

export const NotificationForm: FC<NotificationFormProps> = ({
  formTitle = "New Production Notification form",
}) => {
  const queryClient = useQueryClient();
  const { seriesCcid, titleCcid } = useParams();
  const type = titleCcid ? TITLES : SERIES;
  const riskId = titleCcid || seriesCcid;
  const initialFormData = useMemo(
    () =>
      groupMenuSetting.reduce((acc, current) => {
        acc[current.id] = {};
        return acc;
      }, {}),
    [],
  );
  const cardRef = useRef<any>();

  const { data: metaDataDropdown } = useMetadataDropdown();
  const { data: summary } = useRiskSummary(riskId, type);
  const {
    data: risk,
    isLoading: loadingRisk,
    error: riskError,
  } = useRisk(riskId, type);
  const { data: riskScore } = useRiskScore(riskId as string, type);
  const [currentTab, setCurrentTab] = useState(groupMenuSetting[0].id);
  const [formData, setFormData] = useState<any>(initialFormData);
  const [step, setStep] = useState<number>(1);
  const [autoSaveAt, setAutoSaveAt] = useState<string>("");
  const [formConfig, setFormConfig] = useState<any>(
    groupMenuSetting[0].formConfig,
  );
  const [hideCompliance, setHideCompliance] = useState(false);

  const sideNavMenu = useMemo(
    () =>
      groupMenuSetting.filter(({ id }) => {
        if (hideCompliance) {
          return id !== "compliance";
        }

        return true;
      }),
    [hideCompliance],
  );

  let message =
    "There is an error on submitting the form data, please contact us.";

  const { mutate: submitForm, isLoading: isSubmitting } = useSubmitRisk(
    () => {
      // navigate(redirectUrl);
      enqueueSnackbar("Form successfully submitted!", {
        variant: "success",
        action: SnackbarDismiss as any,
      });

      ModalService.getInstance()
        .setComponent(
          <RiskScoreDialog
            onStart={() => {
              queryClient.invalidateQueries("risk score");
              queryClient.invalidateQueries("risk");
              queryClient.invalidateQueries("risk summary");
            }}
          />,
        )
        .setShowHeader(false)
        .setShowFooter(false)
        .setContentPadding("0px")
        .setWidth("900px")
        .showModal();
    },
    (e) => {
      if (typeof e === "object") {
        message = convertToHtmlStr(e?.response?.data);
      }
      enqueueSnackbar(message, { variant: "error", action: SnackbarDismiss });
    },
  );

  const setAutoSaveDate = useCallback((response: any) => {
    const latestDate = getLatestDate(response) || new Date().toISOString();
    setAutoSaveAt(latestDate);
  }, []);

  // TODO: should find a better way to handle errors
  const { mutate: updateForm } = useUpdateRisk(
    (response) => {
      setAutoSaveDate(response);
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar(message, { variant: "error", action: SnackbarDismiss });
    },
  );

  useEffect(() => {
    setStep(sideNavMenu.findIndex(({ id }) => id === currentTab) + 1);
  }, [currentTab, sideNavMenu]);

  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.scrollTop = 0;
    }
  }, [currentTab]);

  useEffect(() => {
    setFormConfig(sideNavMenu[step - 1].formConfig);
  }, [sideNavMenu, step]);

  useEffect(() => {
    if (risk) {
      setFormData(sanitizeFormData(risk, groupMenuSetting, true));
      setAutoSaveDate(risk);
      setHideCompliance(!risk?.basicInfo?.outsideLocation);
    }
  }, [risk, metaDataDropdown, setAutoSaveDate]);

  useEffect(() => {
    if (metaDataDropdown) {
      initSelectOptions(metaDataDropdown, groupMenuSetting);
    }
  }, [metaDataDropdown]);

  const valueChangeHandler = useCallback(
    (id: string, newValue: any, currentFormData: any) => {
      const copyFromData = { ...currentFormData };
      copyFromData[currentTab][id] = newValue;

      setFormData(copyFromData);

      if (id === "outsideLocation") {
        setHideCompliance(!newValue);
      }
    },
    [currentTab],
  );

  const blurHandler = useCallback(
    (id: any, newValue: any) => {
      // code to save update in the backend.
      let matchedFormField;

      for (const { formFields } of formConfig) {
        for (const formField of formFields) {
          if (formField.id === id) {
            matchedFormField = formField;
            break;
          }
        }

        if (matchedFormField) {
          break;
        }
      }

      if (matchedFormField && risk) {
        const changes: any = {};
          changes[currentTab] = {
            [id]: transFormDataBaseOnTypeAndValue(matchedFormField, newValue),
          };

        updateForm({ riskId, formData: changes, type });
      }
    },
    [currentTab, formConfig, risk, riskId, type, updateForm],
  );

  const validateForm = useCallback(
    (currentFormData: any) => {
      const result: any = validateFormData(currentFormData, groupMenuSetting);

      const { hasError, errorTab } = result;

      if (hasError && errorTab) {
        setCurrentTab(errorTab);
        setFormConfig([
          ...(groupMenuSetting.find(({ id }) => id === errorTab)?.formConfig ||
            []),
        ]);

        setTimeout(() => {
          if (cardRef.current) {
            const errorRef: HTMLDivElement | null =
              document.querySelector(".error");

            if (errorRef) {
              cardRef.current.scrollTop =
                errorRef.offsetTop - cardRef.current.offsetTop;
            }
          }
        }, 500);

        return false;
      }

      return true;
    },
    [],
  );

  const submitHandler = useCallback(() => {
    if (validateForm(formData)) {
      ModalService.getInstance()
        .setTitle("SPOT Declaration of Truth")
        .setWidth("510px")
        .setComponent(
          <Box>
            <Typography variant="subtitle1">
              Please verify the validity of the following statement
            </Typography>
            <Typography
              variant="body1"
              fontStyle="italic"
              padding="0.5rem"
              fontWeight="bold"
              paddingBottom={0}
            >
              I declare that the information provided in these forms is true and
              accurate at this point in time, to the best of my knowledge. I
              will endeavour to amend theses forms, when additional information
              or new requirements arise at any time within the production.
            </Typography>
          </Box>,
        )
        .setCancelLabel("Cancel")
        .setConfirmationLabel("Verify & submit form")
        .setConfirmCallback(() => {
          submitForm({
            riskId,
            formData: sanitizeFormData(formData, groupMenuSetting),
            type,
          });
        })
        .setShowFooter(true)
        .showModal();
    }
  }, [formData, riskId, submitForm, type, validateForm]);

  const Loading = (
    <Stack justifyContent="center" alignItems="center" m={8}>
      <CircularProgress />
    </Stack>
  );

  const Content = (
    <>
      <FormHeader formTitle={formTitle} summary={summary} showFormStatus />
      <Container fixed disableGutters ref={cardRef}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SideNav
              formData={formData}
              isSubmitting={isSubmitting}
              groupMenuSetting={sideNavMenu}
              onStepChange={(tab: string) => {
                setCurrentTab(tab);
              }}
              autoSaveAt={autoSaveAt}
              activeStep={currentTab}
              onSubmitForm={submitHandler}
              riskScore={riskScore}
            />
          </Grid>
          <Grid item xs={9}>
            <Box>
              <Typography variant="h5">
                Section {step} of {sideNavMenu.length}:{" "}
                {sideNavMenu[step - 1].label}{" "}
              </Typography>
              {riskScore?.[sideNavMenu[step - 1].id]?.assessor && (
                <Box mt={2}>
                  <RiskContact
                    riskAssessor={
                      riskScore?.[sideNavMenu[step - 1].id]
                        ?.assessor as RiskAssessor
                    }
                    riskLevel={riskScore?.[sideNavMenu[step - 1].id]?.rating}
                    riskNotes={riskScore?.[sideNavMenu[step - 1].id]?.notes}
                  />
                </Box>
              )}
              <Box>
                {sideNavMenu[step - 1].description && (
                  <Typography mb={2}>
                    {sideNavMenu[step - 1].description}
                  </Typography>
                )}
                <DynamicForm
                  config={formConfig}
                  onValueChange={(id, newValue) =>
                    valueChangeHandler(id, newValue, formData)
                  }
                  onBlur={(id, newValue) => {
                    blurHandler(id, newValue);
                  }}
                  formData={formData[currentTab] || {}}
                />
                <Box my={2}>
                  <Typography display="inline" fontStyle="italic">
                    Indicates a required field{" "}
                    <Typography display="inline" color="red">
                      *
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <ModalWrapper />
    </>
  );

  return (
    <>
      {loadingRisk && Loading}
      {!loadingRisk && riskError && (
        <NotificationFormError riskError={riskError} />
      )}
      {!loadingRisk && !riskError && Content}
    </>
  );
};
