import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { HomepageGreeting } from "../../componentsV2/HomepageGreeting";
import {
  useAssignedProductions,
  useLabelUserEntitlements,
  useActiveProductions,
} from "../../hooks";
import { useGetUserProfile } from "../../hooks/queries/useGetUserProfile";
import CreateCcid from "../../containers/CreateCcid/CreateCcid";
import ProductionCard from "../../componentsV2/ProductionCard/ProductionCardNew";
import { RecentProductionsList } from "../../componentsV2/RecentProductionsList";
import { ModalWrapper } from "../../components";
import { AssignedProductionsCountCard } from "../../componentsV2/AssignedProductionsCountCard";
import theme from "../../utils/theme";
import CreateCcidCard from "../../componentsV2/ProductionCard/CreateCcidCard";
import ActiveProductionsList from "../../componentsV2/ProductionCard/ActiiveProductionsList";

export function Homepage() {
  const [isCCIDModalOpen, setCCIDModalOpen] = useState<boolean>(false);
  const { data: assignedProductions, isLoading: isLoadingAssignedProductions } =
    useAssignedProductions();
  const { data: entitlement, isLoading: isLoadingEntitlements } =
    useLabelUserEntitlements();
  const { data: activeProductions, isLoading: isLoadingActiveProductions } =
    useActiveProductions();
  const { data: profile, isLoading: isLoadingProfile } = useGetUserProfile();
  const userAssignedProductions = assignedProductions?.assignedCcids || [];

  const isLoading =
    isLoadingAssignedProductions ||
    isLoadingEntitlements ||
    isLoadingProfile ||
    isLoadingActiveProductions;

  if (isLoading) {
    return (
      <Stack alignItems="center" m={8}>
        <CircularProgress />
      </Stack>
    );
  }

  const canCreateCcid = entitlement?.canCreateCcid || false;
  const canManageAccess = entitlement?.canManageAccess || false;

  const isHeadOfProductionView = activeProductions
    ? activeProductions.ccids.length > 0 && canManageAccess
    : false;

  return (
    <Container maxWidth="lg" sx={{ mt: 6 }}>
      <Stack direction="row" justifyContent="space-between">
        <HomepageGreeting userProfile={profile} />

        {isHeadOfProductionView ? null : (
          <AssignedProductionsCountCard
            numberOfProductions={userAssignedProductions.length}
          />
        )}
      </Stack>

      {userAssignedProductions.length === 0 || isHeadOfProductionView ? null : (
        <Box marginTop={6}>
          <Typography
            variant="h6"
            letterSpacing="0.15px"
            color={theme.palette.text.secondary}
          >
            Assigned productions:
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.text.secondary}
            letterSpacing="0.4px"
            fontSize="0.875rem"
          >
            You’ve been assigned to one or more productions, this means that
            there is some action you are expected to complete for the following
            productions.
          </Typography>
          <Stack direction="column" spacing={2} marginTop="16px">
            {userAssignedProductions.map((productionCcid: string) => (
              <ProductionCard key={productionCcid} ccid={productionCcid} />
            ))}
          </Stack>
        </Box>
      )}

      {isHeadOfProductionView ? (
        <Box marginTop={6}>
          <Typography
            variant="h6"
            letterSpacing="0.15px"
            color={theme.palette.text.secondary}
          >
            Active productions:
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.text.secondary}
            letterSpacing="0.4px"
            fontSize="0.875rem"
          >
            Here are your active productions.
          </Typography>
          <ActiveProductionsList ccids={activeProductions?.ccids || []} />
        </Box>
      ) : null}

      {canCreateCcid && (
        <CreateCcid open={isCCIDModalOpen} setOpen={setCCIDModalOpen} />
      )}

      <Grid container direction="row" spacing="16px" marginY="16px">
        {canCreateCcid && (
          <Grid item xs={3}>
            <Typography
              variant="h6"
              letterSpacing="0.15px"
              color={theme.palette.text.secondary}
            >
              Create a new production:
            </Typography>
          </Grid>
        )}

        <Grid item xs={canCreateCcid ? 9 : 12}>
          <Typography
            variant="h6"
            letterSpacing="0.15px"
            color={theme.palette.text.secondary}
          >
            Recently visited productions:
          </Typography>
        </Grid>

        {canCreateCcid && (
          <Grid item xs={3}>
            <CreateCcidCard openCcidModal={setCCIDModalOpen} />
          </Grid>
        )}

        <Grid item xs>
          <RecentProductionsList />
        </Grid>
      </Grid>

      <ModalWrapper />
    </Container>
  );
}
