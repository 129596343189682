/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getLegalData } from "../../api/spotlight";

export const useGetProductionPrinciplesData = (
  ccid: string,
  provisional: boolean,
): UseQueryResult<any, AxiosError> => {
  const formType = provisional
    ? `PRODUCTION_PRINCIPLES_PROVISIONAL`
    : `PRODUCTION_PRINCIPLES_FINAL`;
  return useQuery(
    ["productionPrinciplesData", ccid],
    () => getLegalData(ccid as string, formType),
    {
      enabled: Boolean(ccid),
    },
  );
};
