import { useMutation, UseMutationResult } from "react-query";
import { patchSynopsis } from "../../api/synopsis";
import { noop } from "../../utils/appHelper";

export const useUpdateSynopsis = (
  level: string,
  activeCcid: string,
  synopsisValues: Record<string, string>,
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(() => patchSynopsis(level, activeCcid, synopsisValues), {
    onError,
    onSuccess,
  });
